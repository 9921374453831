<template>
    <div>
        <DisableInput />
        <div class="riddle-description strang-kriche">
            <img class="desc-image" :src="descImg" alt="" />
            <!-- <RiddleDescriptionSound :sound="sound" /> -->
            <RiddleDescriptionVideo :video="video" />
            <p>
                {{ $t('Views.Game.Kirche.Fragment.Description') }}
            </p>
            <router-link class="btn-map" to="/game/map">
                {{ $t('Utilities.Fragments.Button') }}
            </router-link>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted } from '@vue/runtime-core'
import { useApi } from '@/lib/useApi.js'
import { useStore } from 'vuex'

import * as sound from '@/assets/kirche/fragment/sound.mp3'
import * as video from '@/assets/kirche/fragment/video.mp4'
import * as descImg from '@/assets/kirche/background.jpg'
import RiddleDescriptionSound from '@/components/Game/RiddleDescriptionSound'
import RiddleDescriptionVideo from '@/components/Game/RiddleDescriptionVideo.vue'
import DisableInput from '../../../components/Game/DisableInput.vue'

export default defineComponent({
    components: {
        RiddleDescriptionSound,
        RiddleDescriptionVideo,
        DisableInput,
    },
    setup() {
        const store = useStore()

        onMounted(() => {
            useApi('forceSolveRiddle', {
                uuid: store.state.game.currentGame.uuid,
                currentPage: window.location.pathname,
            })
        })

        return { sound, video, descImg }
    },
})
</script>

<style scoped></style>
