<template>
    <div class="popup">
        <div id="jigsaw"></div>
    </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core'
import headbreaker from 'headbreaker'
import * as imgSrc from '@/assets/kirche/wegweiser/wegweiser_jigsaw.jpg'
import { useForceChangePage } from '@/lib/useChangePage'
import { useStore } from 'vuex'
import router from '../../router'
import { useRouter } from 'vue-router'
import { useEmitter } from '../../lib/useEmitter'

export default {
    props: {
        imgSrc: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const store = useStore()
        const router = useRouter()
        const emitter = useEmitter()

        onMounted(() => {
            window.headbreaker = headbreaker
            let jigsawImage = new Image()
            jigsawImage.src = imgSrc

            jigsawImage.onload = () => {
                const canvas = new headbreaker.Canvas('jigsaw', {
                    width: 1000,
                    height: 1000,
                    pieceSize: 100,
                    proximity: 20,
                    borderFill: 10,
                    strokeWidth: 0.2,
                    strokeColor: '#333',
                    lineSoftness: 0.0,
                    image: jigsawImage,
                    // optional, but it must be set in order to activate image scaling
                    preventOffstageDrag: true,
                })

                canvas.adjustImagesToPuzzleHeight()
                canvas.autogenerate({
                    horizontalPiecesCount: 6,
                    verticalPiecesCount: 6,
                })

                canvas.attachSolvedValidator();
                canvas.onValid(() => {
                    setTimeout(() => {
                        emitter.emit('forceSolve')
                    }, 10);
                })


                canvas.shuffle(0.7)
                canvas.draw()
            }
        })
    },
}
</script>

<style scoped>
#jigsaw {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    border: 1px solid var(--base-white);
}
.popup-close-button {
    position: absolute;
    cursor: pointer;
    width: 20px;
    text-align: center;
    top: 10px;
    right: 10px;
    z-index: 3;
    border: 1px solid #fff;
    background-color: #fff;
    border-radius: 7px;
    padding: 7px;
}
.popup {
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 2;
    height: 100vh;
    width: 100%;
}
</style>
