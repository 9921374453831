<template>
    <div>
        <div
            v-if="!store.state.game.currentGame.descriptionRead"
            class="riddle-description strang-kriche"
        >
            <img class="desc-image" :src="descImg" alt="" />
            <!-- <RiddleDescriptionSound :sound="sound" /> -->
            <p>
                {{ $t('Views.Game.Kirche.Altar.Description') }}
            </p>
            <button
                @click="
                    store.commit({
                        type: 'updateDescriptionRead',
                        descriptionRead: true,
                    })
                "
            >
                {{ $t('Utilities.Riddles.DescriptionReadButton') }}
            </button>
        </div>
        <div
            v-show="store.state.game.currentGame.descriptionRead"
            class="riddle"
        >
            <img class="riddle-img" :src="altar" />
            <svg
                class="riddle-svg"
                viewBox="0 0 1537 2049"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    class="btn-svg"
                    @click="openPopupBild"
                    d="M585 753.5L930.5 755L950 1232.5H557.5L585 753.5Z"
                />
            </svg>
        </div>
    </div>
</template>

<script>
import { defineComponent, inject } from '@vue/runtime-core'
import { useStore } from 'vuex'
import * as altar from '@/assets/kirche/altar/altar.jpeg'
import * as bildOriginal from '@/assets/kirche/altar/bild_original.jpg'
import * as descImg from '@/assets/kirche/background.jpg'
import * as sound from '@/assets/kirche/altar/sound.mp3'
import RiddleDescriptionSound from '@/components/Game/RiddleDescriptionSound'

import * as pageKanzel from '@/assets/kirche/pages/page_kanzel.jpg'
import * as pageSteintafel from '@/assets/kirche/pages/page_steintafel.jpg'
import * as pageTaufbecken from '@/assets/kirche/pages/page_taufbecken.jpg'
import * as pageAltar from '@/assets/kirche/pages/page_altar.jpg'
import * as pageKanzelEN from '@/assets/kirche/pages/page_kanzel_en.jpg'
import * as pageSteintafelEN from '@/assets/kirche/pages/page_steintafel_en.jpg'
import * as pageTaufbeckenEN from '@/assets/kirche/pages/page_taufbecken_en.jpg'
import * as pageAltarEN from '@/assets/kirche/pages/page_altar_en.jpg'

export default defineComponent({
    components: { RiddleDescriptionSound },
    setup() {
        const store = useStore()
        const openPopupBild = () => {
            store.commit('addPopup', {
                imgSrc: bildOriginal,
                componentType: 'Popup',
            })
        }

        const $app = inject('$app')
        const pageImageObject = {
            Kanzel: {
                de: pageKanzel,
                en: pageKanzelEN
            },
            Taufbecken: {
                de: pageTaufbecken,
                en: pageTaufbeckenEN
            },
            Steintafel: {
                de: pageSteintafel,
                en: pageSteintafelEN
            },
            Altar: {
                de: pageAltar,
                en: pageAltarEN
            },
        }
            
        store.commit({
            type: 'updateInventory',
            inventory: [
                { name: 'Tagebuchseite', img: pageImageObject.Kanzel[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Taufbecken[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Steintafel[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Altar[$app.$i18n.locale] },
            ],
        })


        return {
            store,
            altar,
            descImg,
            sound,
            openPopupBild,
        }
    },
})
</script>

<style scoped>
.riddle-img,
.riddle-svg {
    aspect-ratio: 3/4;
}
</style>
