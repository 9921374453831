<template>
    <div>
        <div
            v-if="!store.state.game.currentGame.descriptionRead"
            class="riddle-description strang-kriche"
        >
            <img class="desc-image" :src="descImg" alt="" />
            <!-- <RiddleDescriptionSound :sound="sound" /> -->
            <p>
                {{ $t('Views.Game.Kirche.Wegweiser.Description') }}
            </p>
            <button
                @click="
                    store.commit({
                        type: 'updateDescriptionRead',
                        descriptionRead: true,
                    })
                "
            >
                {{ $t('Utilities.Riddles.DescriptionReadButton') }}
            </button>
        </div>
        <div
            v-show="store.state.game.currentGame.descriptionRead"
            class="riddle"
        >
            <img class="riddle-img" :src="wegweiser" />
            <svg
                class="riddle-svg"
                viewBox="0 0 2049 1537"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    class="btn-svg"
                    @click="openPopupJigsaw()"
                    d="M794.5 1203L827.5 1221L850 1238L856 1235L868.5 1241.5L879 1249.5L887 1240.5L907.75 1237L936.5 1224.5L956.5 1275.5L936.5 1282.5L946.5 1291.5L948.5 1300L940.5 1315.5L930.5 1319.5L919.5 1318L901 1310L894 1312.5L879 1302L840 1308L825 1317L807 1320.5L792 1324L783.5 1320.5L779 1312.5L776.5 1299.5L783 1290.5L789 1286L796.5 1283L807 1279.5L810 1274L795 1263L781 1261L770.5 1254L781 1232.5L779 1229.5L784 1221L788 1220L794.5 1203Z"
                />
            </svg>
        </div>
    </div>
    <div class="jigsaw-popup" v-show="showJigsaw">
        <div class="popup-close-button" @click="closeJigsaw">X</div>
        <!-- <JigsawPopup :imgSrc="jigsawSrc" /> -->
        <SchiebeRiddle />
    </div>
</template>

<script>
import { defineComponent, ref, inject } from '@vue/runtime-core'
import { useStore } from 'vuex'
import JigsawPopup from '@/components/Game/JigsawPopup'
import SchiebeRiddle from '@/components/Game/Kirche/SchiebeRiddle'
import * as wegweiser from '@/assets/kirche/wegweiser/wegweiser_park.jpg'
import * as jigsawSrc from '@/assets/kirche/wegweiser/wegweiser_jigsaw.jpg'
import RiddleDescriptionSound from '../../../components/Game/RiddleDescriptionSound.vue'
import * as sound from '@/assets/kirche/altar/sound.mp3'

// Pages
import * as pageKanzel from '@/assets/kirche/pages/page_kanzel.jpg'
import * as pageSteintafel from '@/assets/kirche/pages/page_steintafel.jpg'
import * as pageTaufbecken from '@/assets/kirche/pages/page_taufbecken.jpg'
import * as pageAltar from '@/assets/kirche/pages/page_altar.jpg'
import * as pageKanzelEN from '@/assets/kirche/pages/page_kanzel_en.jpg'
import * as pageSteintafelEN from '@/assets/kirche/pages/page_steintafel_en.jpg'
import * as pageTaufbeckenEN from '@/assets/kirche/pages/page_taufbecken_en.jpg'
import * as pageAltarEN from '@/assets/kirche/pages/page_altar_en.jpg'

import * as descImg from '@/assets/kirche/background.jpg'
import { useEmitter } from '../../../lib/useEmitter'

export default defineComponent({
    components: {
        JigsawPopup,
        SchiebeRiddle,
        RiddleDescriptionSound,
    },
    setup() {
        const store = useStore()
        const showJigsaw = ref(false)
        const emitter = useEmitter()

        const $app = inject('$app')
        const pageImageObject = {
            Kanzel: {
                de: pageKanzel,
                en: pageKanzelEN
            },
            Taufbecken: {
                de: pageTaufbecken,
                en: pageTaufbeckenEN
            },
            Steintafel: {
                de: pageSteintafel,
                en: pageSteintafelEN
            },
            Altar: {
                de: pageAltar,
                en: pageAltarEN
            },
        }
            
        store.commit({
            type: 'updateInventory',
            inventory: [
                { name: 'Tagebuchseite', img: pageImageObject.Kanzel[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Taufbecken[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Steintafel[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Altar[$app.$i18n.locale] },
            ],
        })

        const status = ref('beginning')

        const openPopupJigsaw = () => {
            showJigsaw.value = true
            status.value = 'gaming'

            emitter.emit('start-game')
        }
        const closeJigsaw = () => {
            showJigsaw.value = false
            status.value = 'stopping'

            emitter.emit('stop-game')
        }

        return {
            store,
            showJigsaw,
            wegweiser,
            sound,
            descImg,
            jigsawSrc,
            status,
            openPopupJigsaw,
            closeJigsaw,
        }
    },
})
</script>

<style scoped>
.popup-close-button {
    position: absolute;
    cursor: pointer;
    width: 20px;
    text-align: center;
    top: 10px;
    right: 10px;
    z-index: 3;
    border: 1px solid #fff;
    background-color: #fff;
    border-radius: 7px;
    padding: 7px;
}
</style>
