<template>
    <div>
        <div
            v-if="!store.state.game.currentGame.descriptionRead"
            class="riddle-description strang-kriche"
        >
            <img class="desc-image" :src="descImg" alt="" />
            <!-- <RiddleDescriptionSound :sound="sound" /> -->
            <p>
                {{ $t('Views.Game.Kirche.Aposteldenkmal.Description') }}
            </p>
            <button
                @click="
                    store.commit({
                        type: 'updateDescriptionRead',
                        descriptionRead: true,
                    })
                "
            >
                {{ $t('Utilities.Riddles.DescriptionReadButton') }}
            </button>
        </div>
        <div
            v-show="store.state.game.currentGame.descriptionRead"
            class="riddle"
        >
            <img class="riddle-img" :src="imgSrc" alt="" />
            <svg
                class="riddle-svg"
                viewBox="0 0 1537 2049"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    class="btn-svg"
                    @click="openPopupMarkus"
                    d="M646.5 809.5L647 798.5L651.5 783L669 760.5L688.5 747.5L716.5 739L743.5 744.5L765.5 760.5L781 780L787 796.5L787.5 802L782 808.5L775 809L774.5 811.5L775 815L783.5 825.5L788.5 843.5L789 893V986L648.5 988L648 846.5L650 841L656 838.5V835L650 833.5V828L663 814L660.5 810.5L646.5 809.5Z"
                />
                <path
                    class="btn-svg"
                    @click="openPopupMattheus"
                    d="M460.5 836.5V818L464 800L470 783L476.5 775.5L484 771L491.5 769L498 772.5L508 785L515.5 805V820.5L520.5 986L469 984.5L467 918V860.5L473 836.5H460.5Z"
                />
                <path
                    class="btn-svg"
                    @click="openPopupLukas"
                    d="M920 817L923 800.5L927.5 785.5L932.5 778.5L938.5 772.5L943 769.5L949 768H954.5L962 772.5L967 778.5L975.5 795.5L981 817V828L974 835.5L975 858.5L979.5 863L980 979.5L921.5 982L921 851.5L930 845V834L935 823L920 817Z"
                />
            </svg>
        </div>
    </div>
</template>

<script>
import { defineComponent, inject } from '@vue/runtime-core'
import * as imgSrc from '@/assets/kirche/kanzel/kanzel.jpeg'
import * as popupLukas from '@/assets/kirche/kanzel/lukas.jpg'
import * as popupMattheus from '@/assets/kirche/kanzel/matthaeus.jpg'
import * as popupMarkus from '@/assets/kirche/kanzel/markus.jpg'
import * as descImg from '@/assets/kirche/background.jpg'
import * as sound from '@/assets/kirche/altar/sound.mp3'
import RiddleDescriptionSound from '@/components/Game/RiddleDescriptionSound'

// Pages
import * as pageKanzel from '@/assets/kirche/pages/page_kanzel.jpg'
import * as pageSteintafel from '@/assets/kirche/pages/page_steintafel.jpg'
import * as pageTaufbecken from '@/assets/kirche/pages/page_taufbecken.jpg'
import * as pageAltar from '@/assets/kirche/pages/page_altar.jpg'
import * as pageKanzelEN from '@/assets/kirche/pages/page_kanzel_en.jpg'
import * as pageSteintafelEN from '@/assets/kirche/pages/page_steintafel_en.jpg'
import * as pageTaufbeckenEN from '@/assets/kirche/pages/page_taufbecken_en.jpg'
import * as pageAltarEN from '@/assets/kirche/pages/page_altar_en.jpg'

import { useStore } from 'vuex'

export default defineComponent({
    components: { RiddleDescriptionSound },
    setup() {
        const store = useStore()
        const openPopupLukas = () => {
            store.commit({
                type: 'addPopup',
                imgSrc: popupLukas,
                componentType: 'Popup',
            })
        }
        const openPopupMattheus = () => {
            store.commit({
                type: 'addPopup',
                imgSrc: popupMattheus,
                componentType: 'Popup',
            })
        }
        const openPopupMarkus = () => {
            store.commit({
                type: 'addPopup',
                imgSrc: popupMarkus,
                componentType: 'Popup',
            })
        }

        const $app = inject('$app')
        const pageImageObject = {
            Kanzel: {
                de: pageKanzel,
                en: pageKanzelEN
            },
            Taufbecken: {
                de: pageTaufbecken,
                en: pageTaufbeckenEN
            },
            Steintafel: {
                de: pageSteintafel,
                en: pageSteintafelEN
            },
            Altar: {
                de: pageAltar,
                en: pageAltarEN
            },
        }
            
        store.commit({
            type: 'updateInventory',
            inventory: [
                { name: 'Tagebuchseite', img: pageImageObject.Kanzel[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Taufbecken[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Steintafel[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Altar[$app.$i18n.locale] },
            ],
        })


        return {
            store,
            imgSrc,
            sound,
            descImg,
            openPopupLukas,
            openPopupMattheus,
            openPopupMarkus,
        }
    },
})
</script>

<style scoped>
.riddle-img,
.riddle-svg {
    aspect-ratio: 3/4;
}
</style>
