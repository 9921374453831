<template>
    <div>
        <div
            v-if="!store.state.game.currentGame.descriptionRead"
            class="riddle-description strang-kriche"
        >
            <img class="desc-image" :src="descImg" alt="" />
            <!-- <RiddleDescriptionSound :sound="sound" /> -->
            <p>
                {{ $t('Views.Game.Kirche.Taufbecken.Description') }}
            </p>
            <button
                @click="
                    store.commit({
                        type: 'updateDescriptionRead',
                        descriptionRead: true,
                    })
                "
            >
                {{ $t('Utilities.Riddles.DescriptionReadButton') }}
            </button>
        </div>
        <div
            v-show="store.state.game.currentGame.descriptionRead"
            class="riddle"
        >
            <img class="riddle-img" :src="taufbecken" alt="" />
            <svg
                class="riddle-svg"
                viewBox="0 0 1537 2049"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    class="btn-svg"
                    @click="openPopupTaufbecken"
                    d="M686.5 564L729.5 491.5L782.5 570L756 743H822L884 749.5L919 757.5L937 773L959 801.5L949 1011.5L905 1115L823 1191.5V1482L847.5 1507.5L857.5 1556L885 1572.5L893 1646.5V1703L815.5 1765.5L689.5 1773L616.5 1714L612 1653L623.5 1572.5L650 1556L658.5 1515L675.5 1502L663 1191.5L610 1149.5L561.5 1094.5L518.5 978.5L509.5 807L547 765L610 749.5L718.5 743L686.5 564Z"
                />
            </svg>
        </div>
    </div>
</template>

<script>
import { defineComponent, inject } from '@vue/runtime-core'
import { useStore } from 'vuex'
import * as taufbecken from '@/assets/kirche/taufbecken/taufbecken.jpeg'
import * as taufbeckenPopup from '@/assets/kirche/taufbecken/taufbecken_oben.jpg'
import * as descImg from '@/assets/kirche/background.jpg'
import * as sound from '@/assets/kirche/altar/sound.mp3'

// Pages
import * as pageKanzel from '@/assets/kirche/pages/page_kanzel.jpg'
import * as pageSteintafel from '@/assets/kirche/pages/page_steintafel.jpg'
import * as pageTaufbecken from '@/assets/kirche/pages/page_taufbecken.jpg'
import * as pageAltar from '@/assets/kirche/pages/page_altar.jpg'
import * as pageKanzelEN from '@/assets/kirche/pages/page_kanzel_en.jpg'
import * as pageSteintafelEN from '@/assets/kirche/pages/page_steintafel_en.jpg'
import * as pageTaufbeckenEN from '@/assets/kirche/pages/page_taufbecken_en.jpg'
import * as pageAltarEN from '@/assets/kirche/pages/page_altar_en.jpg'
import RiddleDescriptionSound from '../../../components/Game/RiddleDescriptionSound.vue'

export default defineComponent({
    components: { RiddleDescriptionSound },
    setup() {
        const store = useStore()
        const openPopupTaufbecken = () => {
            store.commit('addPopup', {
                imgSrc: taufbeckenPopup,
                componentType: 'Popup',
            })
        }
        const $app = inject('$app')
        const pageImageObject = {
            Kanzel: {
                de: pageKanzel,
                en: pageKanzelEN
            },
            Taufbecken: {
                de: pageTaufbecken,
                en: pageTaufbeckenEN
            },
            Steintafel: {
                de: pageSteintafel,
                en: pageSteintafelEN
            },
            Altar: {
                de: pageAltar,
                en: pageAltarEN
            },
        }
            
        store.commit({
            type: 'updateInventory',
            inventory: [
                { name: 'Tagebuchseite', img: pageImageObject.Kanzel[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Taufbecken[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Steintafel[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Altar[$app.$i18n.locale] },
            ],
        })

        return { store, sound, taufbecken, descImg, openPopupTaufbecken }
    },
})
</script>

<style scoped>
.riddle-img,
.riddle svg {
    aspect-ratio: 3/4;
}
</style>
