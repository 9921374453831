<template>
    <div class="popup">
        <div class="schiebe">
            <div class="table">
                <div class="row" v-for="(i, row) in board" :key="i">
                    <td
                        v-for="(j, col) in i"
                        :key="j"
                        @click="swapBoard(row, col)"
                    >
                        <span>{{ j }}</span>
                        <img :src="slices[j - 1]" alt="Teil des Rätsels"/>
                    </td>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    createBoard,
    mixBoard,
    isSwappable,
    getSwappableIndexes,
    swap,
    isCorretBoard,
} from './helper'

import * as slice_1 from '@/assets/kirche/wegweiser/slices/wegweiser_slice_1.jpg'
import * as slice_2 from '@/assets/kirche/wegweiser/slices/wegweiser_slice_2.jpg'
import * as slice_3 from '@/assets/kirche/wegweiser/slices/wegweiser_slice_3.jpg'
import * as slice_4 from '@/assets/kirche/wegweiser/slices/wegweiser_slice_4.jpg'
import * as slice_5 from '@/assets/kirche/wegweiser/slices/wegweiser_slice_5.jpg'
import * as slice_6 from '@/assets/kirche/wegweiser/slices/wegweiser_slice_6.jpg'
import * as slice_7 from '@/assets/kirche/wegweiser/slices/wegweiser_slice_7.jpg'
import * as slice_8 from '@/assets/kirche/wegweiser/slices/wegweiser_slice_8.jpg'
import * as slice_9 from '@/assets/kirche/wegweiser/slices/wegweiser_slice_9.jpg'

export default {
    name: 'SchiebeRiddle',
    props: ['size'],
    data() {
        const slices = [
            slice_1,
            slice_2,
            slice_3,
            slice_4,
            slice_5,
            slice_6,
            slice_7,
            slice_8,
            slice_9,
        ]
        return {
            slices,
            board: [],
            intervalId: 0,
        }
    },
    created() {
        this.board = createBoard(3)
        this.board = mixBoard(this.board, 1000)
    },
    unmounted() {
        clearInterval(this.intervalId)
    },
    methods: {
        swapBoard(i, j) {
            if (isSwappable(this.board, [i, j])) {
                const indexes = getSwappableIndexes(this.board, [i, j])
                this.board = swap(this.board, indexes[0], indexes[1])
            }

            if (isCorretBoard(this.board)) {
                // Solve Riddle
                this.emitter.emit('forceSolve')
            }
        },
    },
}
</script>

<style scoped>
.schiebe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    border: 1px solid var(--base-white);
}
span {
    position: absolute;
    bottom: 2px;
    right: 2px;
    color: black;
}
.popup {
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 2;
    height: 100vh;
    width: 100%;
}
.table {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
}
.row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
img {
    width: 100px;
    height: 100%;
    /* object-fit: contain; */
}
td {
    position: relative;
    width: 100px;
    height: 100px;
    padding: 0 !important;
}
td:hover {
    cursor: pointer;
}
</style>
