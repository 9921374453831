<template>
    <div>
        <div
            v-if="!store.state.game.currentGame.descriptionRead"
            class="riddle-description strang-kriche"
        >
            <img class="desc-image" :src="descImg" alt="" loading="lazy" />
            <!-- <RiddleDescriptionSound :sound="sound" /> -->
            <p>
                {{ $t('Views.Game.Kirche.Steintafel.Description') }}
            </p>
            <button
                @click="
                    store.commit({
                        type: 'updateDescriptionRead',
                        descriptionRead: true,
                    })
                "
            >
                {{ $t('Utilities.Riddles.DescriptionReadButton') }}
            </button>
        </div>
        <div
            v-show="store.state.game.currentGame.descriptionRead"
            class="riddle"
        >
            <img
                class="riddle-img"
                :src="steintafelAussen"
                alt="Kircheneingang"
                loading="lazy"
            />
            <svg
                class="riddle-svg"
                viewBox="0 0 2049 1537"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    class="btn-svg btn-steintafel"
                    @click="openPopupDetail"
                    d="M1461 672L1808 585L1832 1537H1471.5L1461 672Z"
                />
                <path
                    class="btn-svg btn-wand"
                    @click="openPopupWand"
                    d="M348.5 468L399 457.5H512.5H698L682.5 745L674 989L508.5 997.5L348.5 993L333 730V508.5L348.5 468Z"
                />
            </svg>
        </div>
    </div>
</template>

<script>
import { defineComponent, inject } from '@vue/runtime-core'
import * as steintafelAussen from '@/assets/kirche/steintafel/kircheneingang.jpg'
import * as steintafelDetail from '@/assets/kirche/steintafel/steintafel_detail_unten.jpg'
import * as steinplatteBoden from '@/assets/kirche/steintafel/steinplatte_boden.jpg'
import * as descImg from '@/assets/kirche/background.jpg'
import * as sound from '@/assets/kirche/altar/sound.mp3'
import RiddleDescriptionSound from '@/components/Game/RiddleDescriptionSound'

// Pages
import * as pageKanzel from '@/assets/kirche/pages/page_kanzel.jpg'
import * as pageSteintafel from '@/assets/kirche/pages/page_steintafel.jpg'
import * as pageTaufbecken from '@/assets/kirche/pages/page_taufbecken.jpg'
import * as pageAltar from '@/assets/kirche/pages/page_altar.jpg'
import * as pageKanzelEN from '@/assets/kirche/pages/page_kanzel_en.jpg'
import * as pageSteintafelEN from '@/assets/kirche/pages/page_steintafel_en.jpg'
import * as pageTaufbeckenEN from '@/assets/kirche/pages/page_taufbecken_en.jpg'
import * as pageAltarEN from '@/assets/kirche/pages/page_altar_en.jpg'

import { useStore } from 'vuex'

export default defineComponent({
    components: { RiddleDescriptionSound },
    setup() {
        const store = useStore()
        const openPopupDetail = () => {
            store.commit('addPopup', {
                imgSrc: steintafelDetail,
                componentType: 'Popup',
            })
        }
        const openPopupWand = () => {
            store.commit('addPopup', {
                imgSrc: steinplatteBoden,
                componentType: 'Popup',
            })
        }

        const $app = inject('$app')
        const pageImageObject = {
            Kanzel: {
                de: pageKanzel,
                en: pageKanzelEN
            },
            Taufbecken: {
                de: pageTaufbecken,
                en: pageTaufbeckenEN
            },
            Steintafel: {
                de: pageSteintafel,
                en: pageSteintafelEN
            },
            Altar: {
                de: pageAltar,
                en: pageAltarEN
            },
        }
            
        store.commit({
            type: 'updateInventory',
            inventory: [
                { name: 'Tagebuchseite', img: pageImageObject.Kanzel[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Taufbecken[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Steintafel[$app.$i18n.locale] },
                { name: 'Tagebuchseite', img: pageImageObject.Altar[$app.$i18n.locale] },
            ],
        })


        return {
            store,
            sound,
            descImg,
            steintafelAussen,
            openPopupDetail,
            openPopupWand,
        }
    },
})
</script>

<style scoped></style>
