<template>
        <div
            class="riddle-description strang-kriche"
        >
            <img class="desc-image" :src="descImg" alt="" />
            <p>
                {{ $t('Views.Game.Kirche.Einleitung.Description') }}
            </p>
            <div class="section">
                <img class="preview" :src="EinleitungImg" alt="" />
            </div>
            <button
                @click="router.push('/game/kirche/wegweiser')"
            >
                {{ $t('Utilities.Alerts.GameResumed.Title') }}
            </button>
        </div>
</template>

<script>
import {
    defineComponent,
    onMounted,
    onUnmounted
} from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import * as descImg from '@/assets/hintergrund.jpg'
import * as EinleitungImg from '@/assets/kirche/Einleitung.jpg'

import * as GlockenTon from '@/assets/kirche/glocken.mp3'

export default defineComponent({
    setup() {
        const router = useRouter()

    const playAudio = new Audio()
    playAudio.src = GlockenTon
    onMounted(() => {
        playAudio.play()
    })
    onUnmounted(() => {
        playAudio.pause()
    })
        
        return{
            descImg,
            router,
            EinleitungImg,
        }
    },
})
</script>

<style scoped>
p{
    background: rgba(132, 93, 39, 0.60);
}
.preview{
    height: 40vh;
    max-width: 500px;
    max-height: 500px;
    margin: auto;
    border-radius: 7px;
    z-index: 2;
}
.section{
    position: relative;
    width: fit-content;
    height: fit-content;
    z-index: 2;
}
</style>
